import DataTable from "@/components/page/DataTable";
import ToolBar from "@/components/page/ToolBar.vue";
import FormDialog from "@/components/page/FormDialog.vue";
import ATags from "@/components/page/ATags.vue";
import ASpan from "@/components/page/ASpan.vue";
import AUploadSlotTrigger from "@/components/page/AUploadSlotTrigger.vue";
import AFormItemUpload from "@/components/page/AFormItemUpload.vue";
import AFormItem from "@/components/page/AFormItem.vue";
import AFormItemText from "@/components/page/AFormItemText.vue";
import AUpload from "@/components/page/AUpload.vue";
import AUploadSingle from "@/components/page/AUploadSingle.vue";
import AUploadMultiple from "@/components/page/AUploadMultiple.vue";
export default {
    install(app) {
        app.component('DataTable', DataTable);
        app.component('ToolBar', ToolBar);
        app.component('FormDialog', FormDialog);
        app.component('ATags', ATags);
        app.component('ASpan', ASpan);
        app.component('AUpload', AUpload);
        app.component('AUploadSingle', AUploadSingle);
        app.component('AUploadMultiple', AUploadMultiple);
        app.component('AUploadSlotTrigger', AUploadSlotTrigger);
        app.component('AFormItemUpload', AFormItemUpload);
        app.component('AFormItemText', AFormItemText);
        app.component('AFormItem', AFormItem);
    }
}