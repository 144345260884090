// 组装动态路由
import {menus} from '@/config/menus'
export const makeAsyncRoutes = () => {
    let accessRoutes = []
    menus.forEach(item => {
        if (item.component) {
            accessRoutes.push({
                path: item.path,
                name: item.name,
                component: () => import('@/views'+item.path),
                meta: {
                    title: item.title,
                    location: [item.title],
                },
            })
        }
        if (item.children?.length > 0) {
            item.children.forEach(childItem => {
                accessRoutes.push({
                    path: childItem.path,
                    name: childItem.name,
                    component: () => import('@/views'+childItem.path),
                    meta: {
                        title: childItem.title,
                        location: [item.title, childItem.title],
                    },
                })
            })
        }
    })
    return accessRoutes
}