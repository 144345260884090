<template>
    <div class="toolbar-wrapper">
        <!-- search form -->
        <div class="flex" style="padding: 0 5px">
            <form-create
                v-if="searchRulesRef.length"
                v-model:api="fApi"
                :rule="searchRulesRef"
                :option="searchOptions"
            />
        </div>
        <!-- sort -->
        <div>
            <el-form
                v-if="sortRules.length"
                :inline="true">
                <el-form-item :label="sortTitle" style="margin: 0">
                    <el-radio-group v-model="sortRef" @change="handleSortChange">
                        <el-radio
                            v-for="(item, index) in sortRules"
                            :key="index"
                            :label="item.value">
                            {{item.title}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </div>
        <!-- tabs -->
        <div class="flex center-v pb-10">
            <div class="w-100">
                <el-tabs
                    v-if="tabsRules.length"
                    v-model="activeRef"
                    @tab-change="handleTabsChange"
                    style="max-width: 100%;display: inline-block">
                    <el-tab-pane
                        v-for="(tab, index) in tabsRules"
                        :key="index"
                        :label="tab.label + (tab?.num >= 0 ? '('+tab.num+')' : '')"
                        :name="tab.name" />
                </el-tabs>
            </div>
            <div class="grow flex end">
                <slot name="tabs-append"></slot>
            </div>
        </div>
        <!-- action -->
        <div>
            <slot name="actions"></slot>
        </div>
    </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
    tabsRules: {type: Array, default: []},
    sortRules: {type: Array, default: []},
    searchRules: {type: Array, default: []},
    active: {type: String, default: ""},
    sort: {type: Number, default: 0},
    sortTitle: {type: String, default: ""},
});
const emits = defineEmits([
    "update:active",
    "update:sort",
    "tabsChange",
    "sortChange",
    "searchSubmit",
    "searchReset",
]);

const activeRef = computed({
    get: () => props.active,
    set: (val) => {
        emits("update:active", val);
    }
});
const handleTabsChange = (tab) => {
    emits("tabsChange", tab);
};

const sortRef = computed({
    get: () => props.sort,
    set: (val) => {
        emits("update:sort", val);
    }
});
const handleSortChange = (val) => {
    emits("sortChange", val);
}

let fApi = {};
const searchRulesRef = ref(props.searchRules);
const searchOptions = {
    form: {
        inline: true,
        showMessage: false,
        // labelWidth: "auto",
        labelPosition: "left",
    },
    row: {gutter: 10},
    resetBtn: {
        show: true,
        click: () => {
            fApi.resetFields();
            emits("searchReset");
        }
    },
    onSubmit: (formData) => {
        emits("searchSubmit", formData);
    },
};
</script>