export const menus = [
    {
        title: "统计",
        name: "statistic",
        icon: "ri-computer-line",
        path: "/statistic",
        component: "",
        children: [
            {
                title: "访问记录",
                name: "accessRecord",
                path: "/statistic/accessRecord",
            },
            {
                title: "租金统计",
                name: "rentSurvey",
                path: "/statistic/rentSurvey",
            },
            {
                title: "出勤统计",
                name: "attendance",
                path: "/statistic/attendance",
            },
        ],
    },
    {
        title: "车辆管理",
        name: "car",
        path: "/car",
        icon: "ri-car-line",
        component: "",
        children: [
            {
                title: "车系列表",
                name: "carSeries",
                path: "/car/carSeries",
            },
            {
                title: "车辆列表",
                name: "carList",
                path: "/car/carList",
            },
            {
                title: "商业险45天内",
                name: "carVciList",
                path: "/car/carVciList",
            },
            {
                title: "交强险45天内",
                name: "carTciList",
                path: "/car/carTciList",
            },
            {
                title: "年检90内",
                name: "carYciList",
                path: "/car/carYciList",
            },
            // {
            //     title: "待租车辆",
            //     name: "forRent",
            //     path: "/car/forRent",
            // },
            {
                title: "已处置车辆",
                name: "disposeList",
                path: "/car/disposeList",
            },
        ],
    },
    {
        title: "维保管理",
        name: "maintenance",
        path: "/maintenance",
        icon: "ri-tools-line",
        component: "",
        children: [
            {
                title: "待维修",
                name: "pendingRepair",
                path: "/maintenance/pendingRepair",
            },
            {
                title: "维修中",
                name: "underRepair",
                path: "/maintenance/underRepair",
            },
            {
                title: "待保养",
                name: "pendingMaintenance",
                path: "/maintenance/pendingMaintenance",
            },
            {
                title: "待大保养",
                name: "pendingMajorMaintenance",
                path: "/maintenance/pendingMajorMaintenance",
            },
            {
                title: "历史维修",
                name: "repairHistory",
                path: "/maintenance/repairHistory",
            },
            {
                title: "历史保养",
                name: "maintenanceHistory",
                path: "/maintenance/maintenanceHistory",
            },
        ],
    },
    {
        title: "违章管理",
        name: "violation",
        path: "/violation",
        icon: "ri-barricade-line",
        component: "",
        children: [
            {
                title: "违章列表",
                name: "violationList",
                path: "/violation/list",
            },
        ],
    },
    {
        title: "订单管理",
        name: "order",
        path: "/order",
        icon: "ri-bill-line",
        component: "",
        children: [
            {
                title: "小程序待确认",
                name: "pendingConfirmation",
                path: "/order/pendingConfirmation",
            },
            {
                title: "预订单",
                name: "pre-order",
                path: "/order/pre-order",
            },
            {
                title: "待送达",
                name: "pendingDelivery",
                path: "/order/pendingDelivery",
            },
            {
                title: "待取回",
                name: "pendingPickup",
                path: "/order/pendingPickup",
            },
            {
                title: "回车信息",
                name: "return",
                path: "/order/return",
            },
            {
                title: "待结算",
                name: "pendingSettlement",
                path: "/order/pendingSettlement",
            },
            {
                title: "未结清",
                name: "unpaid",
                path: "/order/unpaid",
            },
            {
                title: "历史订单",
                name: "history",
                path: "/order/history",
            },
            {
                title: "改价确认",
                name: "priceChange",
                path: "/order/priceChange",
            },
            {
                title: "外调车列表",
                name: "externalTransfer",
                path: "/order/externalTransfer",
            },
        ],
    },
    {
        title: "客户管理",
        name: "customer",
        path: "/customer",
        icon: "ri-user-2-line",
        component: "",
        children: [
            {
                title: "客户列表",
                name: "customerList",
                path: "/customer/list",
            },
            {
                title: "黑名单列表",
                name: "blacklist",
                path: "/customer/blacklist",
            },
        ],
    },
    {
        title: "小程序管理",
        name: "wxmp",
        path: "/wxmp",
        icon: "ri-smartphone-line",
        component: "",
        children: [
            {
                title: "广告列表",
                name: "advertising",
                path: "/wxmp/advertising",
            },
            {
                title: "套餐列表",
                name: "package",
                path: "/wxmp/package",
            },
            {
                title: "素材列表",
                name: "resources",
                path: "/wxmp/resources",
            },
            {
                title: "评论列表",
                name: "comment",
                path: "/wxmp/comment",
            },
            {
                title: "优惠券列表",
                name: "coupon",
                path: "/wxmp/coupon",
            },
        ],
    },
    {
        title: "系统管理",
        name: "system",
        path: "/system",
        icon: "ri-settings-4-line",
        component: "",
        children: [
            {
                title: "职务设置",
                name: "job",
                path: "/system/job",
            },
            {
                title: "部门列表",
                name: "department",
                path: "/system/department",
            },
            {
                title: "员工列表",
                name: "employee",
                path: "/system/employee",
            },
            {
                title: "其他配置",
                name: "settings",
                path: "/system/settings",
            },
            {
                title: "权限列表",
                name: "permissions",
                path: "/system/permissions",
            },
        ],
    },
];