import Store from "@/store";
import {uploadWebTokenApi} from "@/api";

export const randomString = (len = 16) => {
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let str = '';
    for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
}
export const getOssToken = () => {
    return new Promise((resolve, reject) => {
        uploadWebTokenApi().then(res => {
            if (res.data) {
                Store.commit("oss/setToken", res.data);
                resolve();
            }
        }).catch(() => {
            reject();
        });
    })

}