<template>
    <el-table
        width="100%"
        height="100%"
        v-loading="loading"
        :row-key="rowKey"
        :border="border"
        :stripe="stripe"
        :fit="fit"
        :data="data"
        :show-header="showHeader"
        @row-click="handleTableRowClick"
        @row-dblclick="handleTableRowDbClick"
    >
        <component is="el-table-column"
                   v-for="(item,index) in columns"
                   :key="index"
                   :type="item?.type"
                   :prop="item.field"
                   :label="item.label"
                   :align="item?.align ?? 'center'"
                   :show-overflow-tooltip="item?.ellipsis ?? false"
                   :width="item.width">
            <template
                v-if="item.comp === 'image'"
                #default="scope">
                <div class="flex center-v center-h">
                    <el-image
                        preview-teleported
                        :style="{height: item.imgh+'px'}"
                        :src="scope.row[item.field]"
                        :preview-src-list="[scope.row[item.field]]" />
                </div>
            </template>
            <template
                v-if="item.comp === 'switch'"
                #default="scope">
                <span @click.stop="handleSwitchChange(scope.row)">
                    <el-switch
                        v-model="scope.row[item.field]"
                        :active-value="1"
                        :inactive-value="-1" />
                </span>
            </template>
            <template
                v-if="item.comp === 'selection'"
                #default="scope">
                <span>
                    {{item.options[scope.row[item.field]]}}
                </span>
            </template>
            <template
                v-if="item.comp === 'actions'"
                #default="scope">
                <el-button
                    v-for="(action,aindex) in item.options"
                    :key="aindex"
                    :type="action.type"
                    size="small"
                    @click.stop="handleActionButtonClick(action.action, scope.row)">
                    {{ action.title }}
                </el-button>
            </template>
            <template
                v-if="item.comp === 'ellipsis'"
                #default="scope">
                <div class="ellipsis">{{ scope.row[item.field] }}</div>
            </template>
            <template
                v-if="item.comp === 'text'"
                #default="scope">
                <div v-if="item.default && !getPropertyByPath(scope.row, item.field)" class="default">{{item.default}}</div>
                <div >{{ getPropertyByPath(scope.row, item.field) }}</div>
            </template>
            <template #default="scope">
                <slot name="custom" :row="scope.row" :comp="item.comp"></slot>
            </template>
        </component>
    </el-table>
    <div
        v-if="total"
        class="flex end pt-10">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
        />
    </div>
</template>
<script setup>
import {ElMessageBox} from "element-plus";

const props = defineProps({
    rowKey: {type: String, default: "id"},
    stripe: {type: Boolean, default: false},
    border: {type: Boolean, default: true},
    fit: {type: Boolean, default: true},
    showHeader: {type: Boolean, default: true},
    loading: {type: Boolean, default: false},
    columns: {type: Array, default: []},
    data: {type: Array, default: []},
    total: {type: Number, default: 0},
    pageSize: {type: Number, default: 10},


    selectedKeys: {type: Array, default: []},
    selectedRows: {type: Array, default: []},
});
const emits = defineEmits([
    "rowClick",
    "rowDbClick",
    "switchChange",
    "actionButtonClick",
    "pageChange",
]);

// 表格单击行
const handleTableRowClick = (row, column) => {
    console.log("handleTableRowClick");
    emits("rowClick", row, column);
};

// 表格双击行
const handleTableRowDbClick = (row, column) => {
    console.log("handleTableRowDbClick");
    emits("rowDbClick", row, column);
};

// 开关
const handleSwitchChange = (row) => {
    console.log("handleSwitchChange", row);
    emits("switchChange", row);
};

// 操作按钮事件
const handleActionButtonClick = (act, row) => {
    console.log("handleActionButtonClick", act, row);
    if (act === "delete") {
        ElMessageBox.confirm("确定要删除这条数据吗？","提示",{type: 'warning'})
            .then(() => {
                emits("actionButtonClick", act, row);
            }).catch(() => {
        });
    } else {
        emits("actionButtonClick", act, row);
    }
};

// 翻页
const handleCurrentChange = (page) => {
    emits("pageChange", page);
};

// 获取对象属性值 多级属性'a.b' = obj.a.b
const getPropertyByPath = (object, propertyPath) => {
    let properties = propertyPath.split(".");
    let value = object;
    for (let property of properties) {
        if (value) {
            value = value[property];
        }
    }
    return value;
}
</script>