import http from "@/utils/http";

// 图片上传token
export const uploadWebTokenApi = (params) => http.post("/api/direct_upload/upload_web_token", params);
// ocr
export const ocrApi = (param) => http.post("/api/orc/image", param);
export const drivingOcrApi = (param) => http.post("/car/orc/driving_info", param);


// 账号登录
export const adminLoginApi = (params) => http.post("/admin/login", params);
// 账号登录
export const userLoginApi = (params) => http.post("/api/v3/user/old_login", params);
// 账号登录
export const userInfoApi = (params) => http.post("/api/v3/user/get", params);

//职务设置
export const postListApi = (params) => http.post("/manager/post/list", params);

//部门列表
export const departmentListApi = (params) => http.post("/manager/department/list", params);
//部门新增
export const createDepartmentApi = (params) => http.post("/manager/department/store", params);
//部门编辑
export const updateDepartmentApi = (params) => http.post("/manager/department/update", params);
//部门删除
export const deleteDepartmentApi = (params) => http.post("/manager/department/delete", params);

//员工列表
export const adminListApi = (params) => http.post("/manager/admin/list", params);
//员工新增
export const createAdminApi = (params) => http.post("/manager/admin/store", params);
//员工编辑
export const updateAdminApi = (params) => http.post("/manager/admin/update", params);
//员工删除
export const deleteAdminApi = (params) => http.post("/manager/admin/delete", params);
//员工重置密码
export const resetPwdAdminApi = (params) => http.post("/manager/admin/reset_password", params);
//员工分配角色
export const setRoleAdminApi = (params) => http.post("/manager/admin/role", params);


// 其他配置

// 附加服务设置
export const configSurchangeApi = (params) => http.post("/api/v3/car/surchange_config/index", params);
export const createConfigSurchangeApi = (params) => http.post("/api/v3/car/surchange_config/store", params);
export const updateConfigSurchangeApi = (params) => http.post("/api/v3/car/surchange_config/update", params);
export const deleteConfigSurchangeApi = (params) => http.post("/api/v3/car/surchange_config/delete", params);
// 通用配置：调配公司、营销平台
export const configCommonApi = (params) => http.post("/api/v3/config/common/list", params);
export const updateConfigCommonApi = (params) => http.post("/api/v3/config/common/update", params);
// 基础设置
export const getConfigApi = (params) => http.get("/admin/config/index", {params});
export const saveConfigApi = (params) => http.post("/admin/config/store", params);
// 随车饮料
export const configDrinkApi = (params) => http.get("/admin/system/drink/list", {params});
export const createConfigDrinkApi = (params) => http.post("/admin/system/drink/store", params);
export const updateConfigDrinkApi = (params) => http.post("/admin/system/drink/update", params);
export const deleteConfigDrinkApi = (params) => http.post("/admin/system/drink/destory", params);
// 春节折扣
export const configActiveApi = (params) => http.post("/api/v3/car/active_config/index", params);
export const createConfigActiveApi = (params) => http.post("/api/v3/car/active_config/store", params);
export const updateConfigActiveApi = (params) => http.post("/api/v3/car/active_config/update", params);
export const deleteConfigActiveApi = (params) => http.post("/api/v3/car/active_config/delete", params);
// 日租金折扣
export const configLeaseApi = (params) => http.get("/admin/system/lease/list", {params});
export const updateConfigLeaseApi = (params) => http.post("/admin/system/lease/update", params);
// 分类小时价
export const configHourPriceApi = (params) => http.get("/admin/system/hour/list", {params});
export const updateConfigHourPriceApi = (params) => http.post("/admin/system/hour/update", params);
// 杂费
export const configSundryApi = (params) => http.post("/admin/sundry/list", params);


//权限列表
export const permissionListApi = (params) => http.post("/manager/permission/list", params);
//权限新增
export const createPermissionApi = (params) => http.post("/manager/permission/store", params);
//权限编辑
export const updatePermissionApi = (params) => http.post("/manager/permission/update", params);
//权限删除
export const deletePermissionApi = (params) => http.post("/manager/permission/delete", params);


//车辆列表
export const drivingListApi = (params) => http.post("/car/driving/list", params);
// 车辆详情
export const drivingDetailApi = (params) => http.post("/car/driving/edit", params);
//车辆保存
export const createDrivingApi = (params) => http.post("/car/driving/store", params);
//车辆更新
export const updateDrivingApi = (params) => http.post("/car/driving/update", params);
// 车辆成本更新
export const updateDrivingCostApi = (params) => http.post("/api/v3/insure/cost/update", params);
// 获取不同类型车辆数量
export const carCategoryNumApi = (params) => http.post("/api/v3/order/get_car_category", params);
//车辆删除（处置车辆）
export const deleteDrivingApi = (params) => http.post("/car/driving/delete", params);
//处置车辆列表
export const drivingDeleteListApi = (params) => http.post("/car/driving/deleteList", params);


// 获取不同类型车系数量
export const carSeriesCategoryNumApi = (params) => http.post("/api/v3/order/get_car_series_category", params);
// 车系列表
export const carSeriesListApi = (params) => http.post("/admin/car/index", params);
// 新增车系
export const createCarSeriesApi = (params) => http.post("/admin/car/store", params);
// 更新车系
export const updateCarSeriesApi = (params) => http.post("/admin/car/update", params);
// 删除车系
export const deleteCarSeriesApi = (params) => http.post("/admin/car/destory", params);


// 待维修
export const pendingRepairListApi = (params) => http.post("/car/service/list", params);
// 新增维修
export const createCarRepairApi = (params) => http.post("/car/service/store", params);
// 编辑维修
export const updateCarRepairApi = (params) => http.post("/car/service/update", params);
// 待保养
export const pendingMaintenanceListApi = (params) => http.post("/api/v3/order/wait_maintenance", params);
// 待大保养
export const pendingMajorMaintenanceListApi = (params) => http.post("/api/v3/order/wait_tearbox", params);
// 保养历史
export const maintenanceHistoryListApi = (params) => http.post("/car/maintenance/list", params);
// 新增保养
export const createCarMaintenanceApi = (params) => http.post("/car/maintenance/store", params);

// 新建保险单
export const createInsureApi = (params) => http.post("api/v3/insure/company/store", params);


// 访问记录
export const accessListApi = (params) => http.post("/api/visit/index", params);




