<template>
    <div>
        <el-dialog
            v-model="visibleRef"
            :title="title"
            :width="width"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :before-close="handleClose"
        >
            <form-create
                v-model="formDataRef"
                v-model:api="formApi"
                :rule="formRules"
                :option="formOptions"
            />
            <template #footer>
                <el-button :disabled="loading" @click="handleClose">取消</el-button>
                <el-button type="primary" :loading="loading" @click="handleConfirm">提交</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    loading: {type: Boolean, default: false},
    visible: {type: Boolean, default: false},
    title: {type: String, default: "No Title"},
    width: {type: Number, default: 700},
    labelWidth: {type: String, default: "auto"},
    labelPosition: {type: String, default: "left"},
    formRules: {type: Array, default: []},
    formData: {type: Object, default: {}},
    beforeSubmit: Function,
});
const emits = defineEmits([
    "update:visible",
    "update:formData",
    "cancel",
    "confirm",
]);
const visibleRef = computed({
    get: () => props.visible,
    set: (val) => {
        emits("update:visible", val);
    },
});
const formDataRef = computed({
    get: () => props.formData,
    set: (val) => {
        emits("update:formData", val);
    },
});
let formApi = {};
const formOptions = {
    row: {
        gutter: 10,
    },
    form: {
        labelWidth: props.labelWidth,
        labelPosition: props.labelPosition,
    },
    submitBtn: false,
};

// 取消
const handleClose = () => {
    emits("update:visible");
    emits("cancel");
};

// 提交
const handleConfirm = () => {
    formApi.submit((formData) => {
        emits("confirm", formData);
    });
};
</script>