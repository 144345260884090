import {nextTick} from "vue";
import {getStorage, setStorage, clrStorage} from "@/utils/storage";
import Reouer from "@/router";

const avatar = "https://new.web.czmb.tech/static/img/avatar.3543bd4.png";

const state = {
    token: getStorage("TOKEN") || "",
    expiredTime: getStorage("EXPIREDTIME") || "",
    avatar: getStorage("AVATAR") || avatar,
    user: getStorage("USER") || "",
    userType: getStorage("USERTYPE") || "",
    staff: getStorage("STAFF") || "",
    staffType: getStorage("STAFFTYPE") || "",
    department: getStorage("DEPARTMENT") || "",
    isCollapse: getStorage("ISCOLLAPSE") ?? true,
    permissions: getStorage("NAVIGATION") || [],
    tabbar: {bars: [], active: ""},
    location: [],
    asyncRoutesMark: false,
};

const getters = {
    authenticated: (state) => {
        let stu = false;
        if (state.token) {
            let now = Date.parse(new Date());
            if (now < state.expiredTime) {
                stu = true;
            }
        }
        return stu;
    },
};

const mutations = {
    setToken: (state, value) => {
        state.token = "Bearer " + value;
        state.expiredTime = Date.parse(new Date()) + 604800000;
        setStorage("TOKEN", state.token);
        setStorage("EXPIREDTIME", state.expiredTime);
    },
    setUser: (state, value) => {
        state.user = value;
        setStorage("USER", value);
    },
    setUserType: (state, value) => {
        state.userType = value;
        setStorage("USERTYPE", value);
    },
    setStaff: (state, value) => {
        state.staff = value;
        setStorage("STAFF", value);
    },
    setStaffType: (state, value) => {
        state.staffType = value;
        setStorage("STAFFTYPE", value);
    },
    setDepartment: (state, value) => {
        state.department = value;
        setStorage("DEPARTMENT", value);
    },
    // 菜单折叠标志
    setIsCollapse: (state, value) => {
        state.isCollapse = !state.isCollapse;
        setStorage('ISCOLLAPSE', state.isCollapse);
    },
    // 动态路由添加标记
    setAsyncRoutesMark: (state, value) => {
        state.asyncRoutesMark = value;
    },
    // 页面位置
    setLocation: (state, value) => {
        state.location = value;
    },
    // 标签栏添加
    addTabbar: (state, tab) => {
        let index = searchTabByName(tab.name);
        if (index > -1) {
            state.tabbar.bars[index].query = tab.query;
        } else {
            state.tabbar.bars.push(tab);
        }
        console.log('0000',state.tabbar);
        nextTick(() => {
            state.tabbar.active = tab.name;
        });
    },
    // 标签栏切换
    changeTabbar: (state, name) => {
        const index = searchTabByName(name);
        if (index > -1) {
            console.log('111',state.tabbar.bars[index]);
            Reouer.push({path: state.tabbar.bars[index].path, query: state.tabbar.bars[index].query});
        }
    },
    // 标签栏移除
    removeTabbar: (state, name) => {
        const {index, nextTab} = getRemoveTabByName(name);
        state.tabbar.bars.splice(index, 1);
        if (state.tabbar.active === name) {
            state.tabbar.active = nextTab.name;
            Reouer.push({path: nextTab.path, query: nextTab.query});
        }
    },
    // 标签栏批量移除
    removeTabbarCommand: (state, command) => {
        if (command === "all") {
            state.tabbar.bars = [];
            Reouer.push("/");
        } else {
            const nextTab = state.tabbar.bars[searchTabByName(state.tabbar.active)];
            state.tabbar.bars = [];
            state.tabbar.bars.push(nextTab);
            state.tabbar.active = nextTab.name;
            Reouer.push({path: nextTab.path, query: nextTab.query});
        }
    },
    // 复位标签栏
    resetTabbar: (state) => {
        state.tabbar = {bars: [], active: ""};
        state.keepAlive = [];
    },
    // 清除缓存
    clearAll: (state) => {
        state.token = "";
        state.expiredTime = "";
        state.avatar = "";
        state.user = "";
        state.userType = "";
        state.staff = "";
        state.staffType = "";
        state.department = "";
        state.location = [];
        state.tabbar = {bars: [], active: ""};
        state.asyncRoutesMark = false;
        clrStorage();
    },
};

const actions = {};

// 获取标签下标位置
const searchTabByName = (name) => {
    let index = -1;
    state.tabbar.bars.forEach((item, ind) => {
        if (item.name === name) {
            index = ind;
        }
    });
    return index;
};
// 获取移除标签
const getRemoveTabByName = (name) => {
    let res = {
        index: searchTabByName(name),
        nextTab: null
    };
    if (res.index > -1) {
        let frontIndex = res.index - 1;
        let endIndex = state.tabbar.bars.length - 1;
        if (frontIndex >= 0) {
            res.nextTab = state.tabbar.bars[frontIndex];
        } else {
            res.nextTab = state.tabbar.bars[endIndex];
        }
    }
    return res;
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};