import axios from "axios";
import Router from "@/router";
import {getItem} from "./storage";
import {ElMessage} from "element-plus";

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 1000 * 60
});

http.interceptors.request.use(
    config => {
        config.headers["authorization"] = getItem("TOKEN") || "";
        return config;
    },
    err => {
        Promise.reject(err);
    }
);

http.interceptors.response.use(
    res => {
        console.log('response',res);
        if (res.status === 200 || res.data.code === 200) {
            return Promise.resolve(res.data);
        } else {
            // if (res.data.code === 2001) {
            //     Router.replace("/login");
            // }
            ElMessage.error(res.data.message);
            return Promise.reject(res.data);
        }
    },
    err => {
        ElMessage.error(err.response.data.message ?? '服务器错误！');
        return Promise.reject(err);
    }
);

export default http;
